import * as Yup from "yup";

import { Field, Formik } from "formik";
import {
  TailwindComboBox,
  TailwindField,
  TailwindSelect,
  TailwindSelectOption,
  TailwindUploadField,
} from "src/components/shared/forms/tailwind-field";
import {
  checkIfFilesArePdf,
  checkIfFilesAreTooBig,
} from "src/helpers/validation";
import {
  createAppointment,
  getAvailableAppointments,
  uploadAppointmentForm,
} from "src/actions/appointments";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

import { AppointmentPageLoader } from "src/components/shared/loader/page-loader/appointment-page-loader";
import { Button } from "src/components/shared/button";
import { DatePickerInput } from "src/components/shared/forms/datepicker-input";
import { Link } from "react-router-dom";
import { RootState } from "src/reducers";
import { Routes } from "src/helpers/routes";
import { StatusMessage } from "src/components/shared/status-message";
import { getEmployees } from "src/actions/employees";
import { redirect } from "src/actions/common";
import styles from "./AppointmentsForm.module.scss";
import { useAxios } from "src/hooks/useAxios";
import { useDepartments } from "src/hooks/useDepartments";

const corporateApptTypes = [
  {
    value: "8122", // was 1192
    label: "Corporate - DOT Physical",
  },
  {
    value: "8058", // was 3103
    label: "Corporate - Drug Screen",
  },
  {
    value: "8122", // was 1196
    label: "Corporate - Employment Physical",
  },
  {
    value: "8122", // was 3115
    label: "Corporate - Respiratory Fit",
  }
];

const wcApptTypes = [
  {
    value: "8060",
    label: "Worker's Comp - Work Injury",
  },
];

export function CreateAppointmentForm() {
  const dispatch = useDispatch();

  const [
    dispatchCreateAppointment,
    {
      loading: createLoading,
      error: createErr,
      success: createSuccess,
      data: createData,
    },
  ] = useAxios();
  const [dispatchUploadForm, { loading: uploadLoading }] = useAxios();

  const activeOrg = useSelector(
    (state: RootState) => state.user.currentUser.activeOrg
  );

  const [dispatchAvailableAppointments, { loading: loadingDates }] = useAxios();
  const [dispatchGetEmployees, { loading: loadingEmployees }] = useAxios();
  const [departments, loadingDepartments] = useDepartments(true);
  const employees = useSelector((root: RootState) => root.employees.results);
  const apptState: any = useSelector((root: RootState) => root.appointments);
  const accessLevels = useSelector(
    (root: RootState) => root.user.currentUser.accessLevel
  );
  const availableDates = apptState.availableDates || [];
  const availableTimes = apptState.availableTimes || [];
  const innerRef = useRef<any>(null);
  const isWorkCompAccount =
    typeof activeOrg !== "undefined" &&
    activeOrg.name &&
    activeOrg.name.toLowerCase().startsWith("cc wc")
      ? true
      : false;

  const [selectsState, setSelectValue] = useState({
    employeeId: "",
    visitTypeId: "",
    departmentId: "",
    date: "",
    time: "",
  });

  const schema = Yup.object().shape({
    employeeId: Yup.string().required("Required"),
    visitTypeId: Yup.number().required("Required"),
    departmentId: Yup.number().required("Required"),
    date: Yup.string().required("Required"),
    time: Yup.string().required("Required"),
    form: Yup.array()
      .nullable()
      .test("fileSize", "Files are too large", checkIfFilesAreTooBig)
      .test("fileType", "File is not a PDF", checkIfFilesArePdf),
  });

  const initialValues = {
    employeeId: "",
    visitTypeId: "",
    departmentId: "",
    date: "",
    time: "",
    notes: "",
    form: [],
    status: "active",
  };

  function fetchAvailableAppointments(
    visitTypeId: string,
    departmentId: string,
    employeeId: string
  ) {
    if (visitTypeId && departmentId && employeeId) {
      dispatchAvailableAppointments(
        getAvailableAppointments(visitTypeId, departmentId, employeeId)
      );
    }
  }

  function fetchEmployees() {
    dispatchGetEmployees(getEmployees());
  }

  function handleCancel() {
    dispatch(redirect(Routes.appointments));
  }

  function handleSubmit(values: any) {
    dispatchCreateAppointment(createAppointment(values));
  }

  function handleSelect(id: string, selected: any, setValue?: any) {
    if (id !== "employeeId") {
      setSelectValue(prevState => ({
        ...prevState,
        [id]: selected.value,
      }));
    }
    switch (id) {
      case "employeeId":
        setValue("departmentId", "");
        setValue("visitTypeId", "");
        setValue("date", "");
        setSelectValue(prevState => ({
          ...prevState,
          employeeId: selected.value,
          departmentId: "",
          visitTypeId: "",
          date: "",
          time: "",
        }));
        break;
      case "visitTypeId":
        setValue("departmentId", "");
        setValue("date", "");
        setValue("time", "");
        break;
      case "departmentId":
        setValue("date", "");
        setValue("time", "");
        break;
    }
  }

  function handleDateChange() {
    setSelectValue(prevState => ({
      ...prevState,
      time: "",
    }));
  }

  // Fetch all employees once on render
  useEffect(() => {
    fetchEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch appointment dates when employee, visit type, and department are selected

  useEffect(() => {
    if (innerRef.current) {
      if (
        innerRef.current.departmentId !== "" &&
        innerRef.current.values.date === ""
      ) {
        fetchAvailableAppointments(
          selectsState.visitTypeId,
          innerRef.current.values.departmentId,
          innerRef.current.values.employeeId
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerRef, selectsState]);

  useEffect(() => {
    if (createSuccess && !innerRef.current.values.form.length) {
      dispatch(redirect(Routes.appointments + "?success=1"));
    } else if (createSuccess && createData) {
      dispatchUploadForm(
        uploadAppointmentForm(
          createData.contactId,
          innerRef.current.values.form[0]
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess, createData]);

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      innerRef={innerRef}
    >
      {({ handleSubmit, setFieldValue, values, errors, dirty, resetForm }) => {
        // Build date options if available.
        let dateOptions: Date[] = [];
        if (availableDates.length) {
          dateOptions = availableDates.map((date: string) => new Date(date));
        }

        // Build time options based on the user selected date.
        const noDatesText = loadingDates
          ? "Searching for dates..."
          : "No dates found";
        let timeOptions: TailwindSelectOption[] = [];

        if (availableTimes && values.date && typeof values.date === "object") {
          const selectedDate: Date = values.date;
          const selectedDateString: string =
            selectedDate.toLocaleDateString("en-US");

          if (availableTimes[selectedDateString]) {
            Object.keys(availableTimes[selectedDateString]).forEach(
              (time: string, index: number) => {
                // Added name to debug Epic issue
                let payload = JSON.stringify(
                  availableTimes[selectedDateString][time]
                );
                let encodedPayload = Buffer.from(payload).toString("base64");
                timeOptions.push({
                  value: encodedPayload,
                  label: time,
                });
              }
            );
          }
        }

        let employeeOptions: TailwindSelectOption[] = [];

        if (employees) {
          Object.keys(employees).forEach(key => {
            employeeOptions.push({
              value: employees[key].patientId,
              label: employees[key].name,
            });
          });
        }

        const validEmployeeSelected = !!employeeOptions.filter(option => {
          return option.value.toLowerCase() === values.employeeId.toLowerCase();
        }).length;

        return (
          <form className={styles.root} onSubmit={handleSubmit}>
            <div
              className={`${styles.formContent} grid grid-cols-12 ${
                +createLoading ? "overflow-hidden" : ""
              }`}
            >
              <AppointmentPageLoader
                createLoading={createLoading}
                uploadLoading={uploadLoading}
              />
              <div
                className={`${styles.description} col-span-12 lg:col-span-4`}
              >
                <div className="text-lg text-gray-900 leading-6 font-medium">
                  Appointment Details
                </div>
                <div className="text-sm text-gray-500 leading-5">
                  Fill out the appointment details.
                </div>
              </div>
              <div
                className={
                  styles.fields +
                  " " +
                  "grid grid-cols-2 col-span-12 gap-y-8 lg:gap-8 lg:col-span-8"
                }
              >
                <div className="col-span-2">
                  {createErr ? (
                    <StatusMessage type="error">
                      Appointment could not be created:{" "}
                      {createErr.response.data.message}
                    </StatusMessage>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-span-2 grid grid-cols-12 gap-6 lg:gap-9">
                  <div className="col-span-12 lg:col-span-7 xl:col-span-6">
                    <Field
                      id="employeeId"
                      name="employeeId"
                      label="Employee Name"
                      loadingMessage={
                        loadingEmployees
                          ? "Loading employees..."
                          : "No employees found"
                      }
                      placeholder="Please select an employee"
                      component={TailwindComboBox}
                      options={employeeOptions}
                      loading={loadingEmployees}
                      handleSelect={handleSelect}
                      setValue={setFieldValue}
                      resetForm={resetForm}
                      initialValues={initialValues}
                      values={values}
                      handleResetOnChange={() => resetForm()}
                      value={values.employeeId}
                    />
                  </div>
                  <span className="col-span-12 lg:col-span-1 flex items-center lg:mt-6 justify-center">
                    OR
                  </span>
                  <div className="flex items-end justify-end col-span-12 lg:col-span-4 xl:col-span-5">
                    <label
                      htmlFor="createEmployee"
                      className="invisible absolute block"
                    >
                      Create New Employee
                    </label>
                    <Link
                      id="createEmployee"
                      to={Routes.apptCreateEmployee}
                      className={`${styles.createLink} text-sm bg-blue-50 shadow-sm sm:rounded-lg w-full text-center`}
                    >
                      Create New Employee
                    </Link>
                  </div>
                </div>
                <div className={styles.fieldRow}>
                  <div className={styles.inputWrapper}>
                    {accessLevels ? (
                      <Field
                        id="visitTypeId"
                        name="visitTypeId"
                        label="Visit Type"
                        disabled={
                          !dirty ||
                          (errors.employeeId && errors.visitTypeId) ||
                          !validEmployeeSelected
                        }
                        loadingMessage={
                          // Will need to update this loading state dependin
                          loadingDates
                            ? "Loading Visit types..."
                            : "No Visit types found"
                        }
                        placeholder="Please select a visit type"
                        component={TailwindSelect}
                        options={
                          isWorkCompAccount ? wcApptTypes : corporateApptTypes
                        }
                        handleSelect={handleSelect}
                        setValue={setFieldValue}
                        value={values.visitTypeId}
                      />
                    ) : (
                      //placeholder component
                      <Field
                        id="visitTypeId"
                        name="visitTypeId"
                        label="Visit Type"
                        disabled
                        placeholder="Please select a visit type"
                        component={TailwindSelect}
                        options={["loading"]}
                        handleSelect={handleSelect}
                        setValue={setFieldValue}
                        value={values.visitTypeId}
                      />
                    )}
                  </div>
                </div>
                <div className="col-span-2">
                  <Field
                    id="departmentId"
                    name="departmentId"
                    label="Location"
                    loadingMessage={
                      loadingDepartments
                        ? "Loading locations..."
                        : "No locations found"
                    }
                    disabled={
                      !dirty ||
                      (errors.visitTypeId && errors.departmentId) ||
                      !validEmployeeSelected
                    }
                    loading={loadingDepartments}
                    component={TailwindSelect}
                    options={departments}
                    placeholder="Please select a location"
                    handleSelect={handleSelect}
                    setValue={setFieldValue}
                    value={values.departmentId}
                  />
                </div>
                <div className="col-span-2 grid grid-cols-1 lg:grid-cols-2 gap-8">
                  <Field
                    id="date"
                    name="date"
                    label="Date"
                    disabled={
                      !dirty ||
                      (errors.departmentId && errors.date) ||
                      !dateOptions.length ||
                      values.departmentId === "" ||
                      values.visitTypeId === "" ||
                      !validEmployeeSelected ||
                      loadingDates
                    }
                    placeholder={
                      !dateOptions.length || loadingDates
                        ? noDatesText
                        : "Select a date"
                    }
                    handleDateChange={handleDateChange}
                    component={DatePickerInput}
                    includeDates={dateOptions}
                    value={values.date}
                  />
                  <Field
                    id="time"
                    name="time"
                    label="Time"
                    loadingMessage={
                      !availableTimes.length
                        ? "No times found"
                        : "Select a time"
                    }
                    disabled={
                      !dirty ||
                      (errors.date && errors.time) ||
                      !dateOptions ||
                      values.date === ""
                    }
                    component={TailwindSelect}
                    options={timeOptions}
                    placeholder="Please Select a time"
                    handleSelect={handleSelect}
                    setValue={setFieldValue}
                    value={values.time}
                    onClick={() => selectsState.time}
                  />
                </div>
                <div className="col-span-2">
                  <Field
                    type="text"
                    id="notes"
                    name="notes"
                    label="Notes (optional)"
                    disabled={
                      !dirty ||
                      (errors.time && errors.notes) ||
                      values.time === ""
                    }
                    component={TailwindField}
                  />
                </div>
                {/*<div className="col-span-2">
                  <Field
                    id="form"
                    name="form"
                    label="Add Form (optional)"
                    accept=".pdf"
                    acceptText="PDF's up to 10MB"
                    component={TailwindUploadField}
                  />
                </div>*/}
              </div>
            </div>

            <div className={styles.actions}>
              <Button type="button" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="submit" primary disabled={createLoading}>
                Create Appointment
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}
